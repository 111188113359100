import React, { Component } from 'react';
import Modal from "react-modal";
import { Helmet } from "react-helmet";

// Components

// Styles
import './dgCookies.scss';

class DgCookies extends Component {
    constructor(props) {
        super(props);

        this.state = {
            stoageName: 'dgCookies',
            isFirstTime: true,
            isOpen: true,
            isManageOpen: false,
            scripts: []
        }

        this.onSave = this.onSave.bind(this);
        this.onExit = this.onExit.bind(this);
        this.onSaveAndExit = this.onSaveAndExit.bind(this);
        this.toggleOpenClose = this.toggleOpenClose.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSaveAll = this.onSaveAll.bind(this);
        this.toggleManage = this.toggleManage.bind(this);
        this.fireEvent = this.fireEvent.bind(this);
    }

    componentDidMount() {
        let preferances = JSON.parse(sessionStorage.getItem(this.state.stoageName));

        if(
            preferances !== null &&
            preferances !== undefined
        ) {
            this.props.scripts.forEach(script => {
                let preference = preferances.find(pref => pref.category === script.category);
                script.isAccepted = script.isGTM ? true : (preference ? preference.isAccepted : false);
                script.isSaved = script.isGTM ? true : (preference ? preference.isAccepted : false);
            });

            this.setState({
                scripts: this.props.scripts,
                isFirstTime: false,
                isOpen: false,
                isManageOpen: false,
            });
        } else {
            this.props.scripts.forEach(script => {
                script.isAccepted = script.isGTM ? true : false;
                script.isSaved = script.isGTM ? true : false;
            });

            this.setState({
                scripts: this.props.scripts
            });
        }
    }

    // Save preferences to session storage
    onSave() {
        let tmp = [...this.state.scripts];

        // Ensure GTM is always rendered.
        tmp.forEach(script => {
            script.isSaved = true;

            if(script.isGTM === true) {
                script.isAccepted = true;
            }
        });

        this.setState({
            scripts: tmp
        }, () => {
            let saveValues = [];
            this.state.scripts.forEach(script => {
                let isCataegoryNew = !saveValues.some(cat => cat.category === script.category);
    
                if(isCataegoryNew) {
                    let obj = {
                        category: script.category,
                        isAccepted: script.isAccepted
                    };
                    saveValues.push(obj);
    
                    // Fire Event if isAccepted and is not GTM
                    if(script.isAccepted && script.isGTM !== true) {
                        this.fireEvent({
                            event: `${this.state.stoageName.toUpperCase()}_${script.category.toString().toUpperCase()}`,
                            category: `${this.state.stoageName.toUpperCase()}`,
                            action: "Cookie_Accepted",
                            label: "Cookie_Accepted"
                        });
                    }
                }
            });

            sessionStorage.setItem(this.state.stoageName, JSON.stringify(saveValues));
        });
    }

    // Accept and Save all preferances
    onSaveAll() {
        let tmp = [...this.state.scripts];
        tmp.forEach((script) => {
            script.isAccepted = true;
            script.isSaved = true;
        });

        this.setState({
            scripts: tmp
        });

        this.onSaveAndExit();
    }

    onExit() {
        this.forceUpdate();
        this.toggleOpenClose();
    }

    // Open/Close Start Modal
    toggleOpenClose() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    // Open/Close Manage Modal
    toggleManage() {
        this.setState({
            isManageOpen: !this.state.isManageOpen
        });
    }

    onSaveAndExit() {
        this.onSave();
        this.onExit();
    }

    // Handle Change in manage
    onChange(event, index) {
        const value = event.target.checked;

        let tmp = [...this.state.scripts];
        tmp.forEach(script => {
            if(script.category === index.category) {
                script.isAccepted = value;
            }
        });

        this.setState({
            scriptsTmp: tmp
        });
    }

    fireEvent(event) {
        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push(event);
    }

  render() {
    let isScriptsEmpty = this.state.scripts && this.state.scripts.length > 0;
    let cookies_page = this.props.cookies_page_uri ? this.props.cookies_page_uri : `/cookies-policy`;
    let renderScripts = this.state.scripts.filter(script => script.isAccepted === true && script.isSaved === true).map((value) => (
        value.code
    ));
    let renderScriptsTogglesValues = [];
    let renderScriptsToggles = [];

    if(isScriptsEmpty) {
        this.state.scripts.forEach((value, index) => {
            if(!value.isGTM) {
                let isCataegoryNew = !renderScriptsTogglesValues.some(cat => cat.category === value.category);

                if(isCataegoryNew) {
                    renderScriptsTogglesValues.push(value);
                    renderScriptsToggles.push(
                        <div 
                            key={`dg-cookie-${index}`}
                            className="container-row"
                        >
                            <div className="text">
                                <h3>{value.category}</h3>
                            </div>
                            <div className="toggle">
                                <label className="label toggle">
                                    <input 
                                        type="checkbox" 
                                        name="isAccepted" 
                                        className="toggle_input"
                                        checked={value.isAccepted} 
                                        onChange={(event) => this.onChange(event, value)}
                                    />
                                    <div className="toggle-control">
                                        {
                                            value.isAccepted ? (
                                                <span className="toggle-on">On</span>
                                            ): (
                                                <span className="toggle-off">Off</span>
                                            )
                                        }
                                    </div>
                                </label>
                                
                            </div>
                        </div>
                    );
                }
            }
        });
    }

    return (
        <Modal
            isOpen={true}
            onRequestClose={() => this.onExit()}
            shouldCloseOnOverlayClick={false}
            contentLabel="Cookies Policy"
            className="dg-cookie-container"
            overlayClassName={`dg-cookie-overlay-container ${!this.state.isManageOpen ? 'start' : ''} ${this.state.isOpen ? '' : 'closed'}`} // closed -> needed to stop reac-helmet unmounting
        >
            <Helmet>
                {renderScripts}
            </Helmet>
            {
                !this.state.isManageOpen ? (
                <div className="dg-cookie-overlay">
                    <div className="dg-cookie">
                        {
                            this.props.our_use_info ? this.props.our_use_info : (
                            <div className="item">
                                <h2>Our use of cookies </h2>
                                <p>We use necessary cookies to make our site work. We'd also like to set optional analytics cookies to help us improve it. We won't set optional cookies unless you enable them. Using this tool will set a cookie on your device to remember your preferences.</p>
                                <p>For more detailed information about the cookies we use, see our <a href={cookies_page}>Cookies page</a></p>
                            </div>
                            )
                        }
                        
                        <div className="item button-container">
                            <button
                                type="button"
                                className="btn-primary"
                                onClick={() => this.onSaveAll()}
                            >
                                Accept All
                            </button>
                            <button
                                type="button"
                                className="btn-primary"
                                onClick={() => this.toggleManage()}
                            >
                                Manage Preferences
                            </button>
                        </div>
                    </div>
                </div>
                ) : (
                    <div className="dg-cookie-overlay">
                        <div className="dg-cookie">
                                {
                                    this.props.our_use_info ? this.props.our_use_info : (
                                        <div className="item">
                                            <h2>Our use of cookies </h2>
                                            <p>We use necessary cookies to make our site work. We'd also like to set optional analytics cookies to help us improve it. We won't set optional cookies unless you enable them. Using this tool will set a cookie on your device to remember your preferences.</p>
                                            <p>For more detailed information about the cookies we use, see our <a href={cookies_page}>Cookies page</a></p>
                                        </div>
                                    )
                                }
                                {
                                    this.props.necessary_info ? this.props.necessary_info : (
                                        <div className="item">
                                            <h3>Necessary cookies</h3>
                                            <p>Necessary cookies enable core functionality such as security, network management, and accessibility. You may disable these by changing your browser settings, but this may affect how the website functions.</p>
                                        </div>
                                    )
                                }
                                {
                                    this.props.additional_info ? this.props.additional_info : (
                                        <div className="item">
                                            <h3>Analytics cookies</h3>
                                            <p>We'd like to set Google Analytics cookies to help us to improve our website by collecting and reporting information on how you use it. The cookies collect information in a way that does not directly identify anyone. For more information on how these cookies work, please see our <a href={cookies_page}>Cookies page</a>.</p> 
                                        </div>
                                    )
                                }
                                
                                {
                                    isScriptsEmpty ? (
                                        <div className="item">
                                            {renderScriptsToggles}
                                        </div>
                                    ) : ''
                                }
                                <div className="item">
                                    <button
                                        type="button"
                                        className="btn-primary"
                                        onClick={() => this.onSaveAndExit()}
                                    >
                                        Save and close
                                    </button>
                                </div>
                        </div>
                    </div>
                )
            }
            
        </Modal>
    )
  }
}

export default DgCookies;
