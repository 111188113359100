import React, { Component } from 'react';

// Styles
import './container.scss';

// Actions

// Components

class Container extends Component {

    render() {
        return (
            <div className="dg-container">
                <div className="login-container">
                    <div className="header-container">
                        <div className="header">
                            {this.props.header}
                        </div>
                    </div>
                    <div className="content">
                        {this.props.content}
                    </div>
                </div>
            </div>
        );
    }
}

export default Container;
