import React, { Component } from 'react';

// Styles
import './spinner.scss';

// Actions

// Components

// Assets
import {
    spinner1,
    gear
} from './assets/spinners';

class DgSpinner extends Component {

    constructor(props) {
        super(props);

        this.getSpinner = this.getSpinner.bind(this);
    }

    getSpinner(type) {
        let tmpType = type.toUpperCase();
        switch (tmpType) {
            case 'CUSTOM':
                return this.props.customSpinner;
            case 'GEAR':
                return gear;
            case 'DEFAULT':
                return spinner1;
            default:
                return spinner1;
        }
    }

    render() {
        return (
            <div className="dg-spinner-container">
                <div className="dg-spinner">
                    {this.getSpinner(this.props.type || '')}
                </div>
            </div>
        );
    }
}

export default DgSpinner;
