import React, { Component } from 'react';
// import LazyLoad from 'react-lazyload';
import VizSensor from 'react-visibility-sensor';

// Styles
import './dgLazy.scss';

// Actions

// Components

// Assets

class DgLazy extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            isCurrentltyVisible: false,
            isVisible: false,
            isLoaded: false
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange(isVisible) {
        console.log(isVisible);
        this.setState({isCurrentltyVisible: isVisible});
        if(
            isVisible && 
            this.state.isLoaded === false &&
            this.state.isVisible === false
        ) {
            this.setState({
                isVisible: isVisible,
                isLoaded: true
            });
        }
    }

    render() {

        return (
            /*
            <LazyLoad
                className={this.props.className ? this.props.className : ""}
                once={this.props.once ? this.props.once: false}
                offset={this.props.offset ? this.props.offset : 100}
                scroll={this.props.scroll ? this.props.scroll: true}
                resize={this.props.resize ? this.props.resize: true}
                placeholder={this.props.placeholder ? this.props.placeholder : undefined}
                classNamePrefix={this.props.classNamePrefix ? this.props.classNamePrefix : 'dg-lazy'}
            >
                {this.props.children}
            </LazyLoad>
            */
            <VizSensor
                partialVisibility={true}
                offset={this.props.offset ? this.props.offset : {}}
                scrollCheck={this.props.scrollCheck ? this.props.scrollCheck: true}
                resizeCheck={this.props.resizeCheck ? this.props.resizeCheck: true}
                onChange={(isVisible) => {
                    this.onChange(isVisible)
                }}
            >
                {this.state.isLoaded ? this.props.children : (
                    this.props.loading ? 
                    this.props.loading : (
                        <div 
                            className="dg-lazy-loading" 
                            style={this.props.style ?  this.props.style: {}}
                        >
                            Loading .... {`${this.state.isLoaded}`} :: {`${this.state.isVisible}`} :: {`${this.state.isCurrentltyVisible}`}
                        </div>
                    )
                )}
            </VizSensor>
        );
    }
}

export default DgLazy;
